<template>
  <div class="header-container">
    <div class="search-section">
      <el-input 
        v-model="query" 
        :placeholder="KT('device.search')" 
        class="search-input"
      ></el-input>
      
      <div class="actions-group">
        <el-button
          type="primary"
          @click="showModal"
          @mouseleave="hideTip"
          @mouseenter.stop="showTip($event, 'Informes')"
          class="action-button reports-button-top"
        >
          <i class="fas fa-file-alt"></i>
        </el-button>

        <el-button
          v-if="store.getters.advancedPermissions(49)"
          @mouseleave="hideTip"
          @mouseenter.stop="showTip($event, KT('group.add'))"
          type="primary"
          @click="editGroupRef.newGroup()"
          plain
          class="action-button"
        >
          <i class="fas fa-folder-plus"></i>
        </el-button>

        <el-button
          @mouseleave="hideTip"
          @mouseenter.stop="showTip($event, KT('device.add'))"
          :disabled="!store.getters['checkDeviceLimit']"
          v-if="store.getters.advancedPermissions(13) && (store.state.auth.deviceLimit === -1 || store.state.auth.deviceLimit > 0)"
          type="primary"
          @click="(store.getters['checkDeviceLimit']) ? editDeviceRef.newDevice() : deviceLimitExceded()"
          class="action-button"
        >
          <i class="fas fa-plus"></i>
        </el-button>
      </div>
    </div>

    <div class="modal-container" v-if="isShowModal">
      <div class="modal">
        <div class="modal-header">
          <div class="flex items-center text-lg">Informes Sobre Dispositivos</div>
          <button aria-label="close" @click="closeModal" class="close-button">
            <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path clip-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" fill-rule="evenodd"></path>
            </svg>
          </button>
        </div>
        <div class="modal-body">
          <div style="display: flex; flex-direction: column; gap: 10px; align-items: flex-start;">
            <el-button class="custom-button" size="small" @click="generatePDF">Dispositivos Activos (PDF)</el-button>
            <el-button class="custom-button" size="small" @click="generateOfflinePDF">Dispositivos OFFline (PDF)</el-button>
            <el-button class="custom-button" size="small" @click="filterLastHour">Última Hora</el-button>
            <el-button class="custom-button" size="small" @click="generateExcel">Dispositivos Activos (Excel)</el-button>
            <el-button class="custom-button" size="small" @click="generateOfflineExcel">Dispositivos OFFline (Excel)</el-button>
            <el-button class="custom-button" size="small" @click="generateJSON">Dispositivos Activos (JSON)</el-button>
            <el-button class="custom-button" size="small" @click="generateOfflineJSON">Dispositivos OFFline (JSON)</el-button>
          </div>
        </div>
        <div class="modal-footer">
          <div class="flex justify-end w-full">
            <button class="decline-button" @click="closeModal">Salir</button>
          </div>
        </div>
      </div>
    </div>
  </div>


  
  <div class="device-container">
    <div class="deviceHead">
      <div class="sorting-buttons-container">
        <div @click="store.dispatch('devices/setSorting', 'name')" class="sorting-button">
          {{ KT('device.name') }}
          <span v-if="store.getters['devices/sorting'] === 'name-asc'">
            <i class="fas fa-sort-alpha-down"></i>
          </span>
          <span v-else-if="store.getters['devices/sorting'] === 'name-desc'">
            <i class="fas fa-sort-alpha-up"></i>
          </span>
          <span v-else>
            <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
          </span>
        </div>
        
        <div @click="store.dispatch('devices/setSorting', 'status')" class="sorting-button">
          {{ KT('device.status') }}
          <span v-if="store.getters['devices/sorting'] === 'status-asc'">
            <i class="fas fa-sort-alpha-down"></i>
          </span>
          <span v-else-if="store.getters['devices/sorting'] === 'status-desc'">
            <i class="fas fa-sort-alpha-up"></i>
          </span>
          <span v-else>
            <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
          </span>
        </div>
        
        <div @click="store.dispatch('devices/setSorting', 'lastUpdate')" class="sorting-button">
          {{ KT('device.updated') }}
          <span v-if="store.getters['devices/sorting'] === 'lastUpdate-asc'">
            <i class="fas fa-sort-alpha-down"></i>
          </span>
          <span v-else-if="store.getters['devices/sorting'] === 'lastUpdate-desc'">
            <i class="fas fa-sort-alpha-up"></i>
          </span>
          <span v-else>
            <i style="color: silver;" class="fas fa-sort-alpha-down"></i>
          </span>
        </div>
        
        <div @click="setSortingByState()" class="sorting-button">
          {{ KT('sorting.' + store.getters['devices/sorting']) }}
          <span><i class="fas fa-sort"></i></span>
        </div>
      </div>
    </div>

    <div ref="realDevices" @scroll="realScroll($event)" class="devices-scroll-container">
      <!-- Contador de dispositivos total -->
      <div class="devices-count">{{ filteredDevices.length }} dispositivos</div>
      
      <!-- Contador de filtros con indicador de cantidad al estilo de grupos -->
      <div v-if="query && query.length > 0" class="filter-counter">
        {{ KT('device.filtered') }}&nbsp;<span class="filter-bubble">{{ filteredDevices.length }}</span>
      </div>
      
      <!-- Vamos voltar ao layout original, mas mantendo algumas melhorias de reatividade -->
      <div class="device-list">
        <div v-for="(group) in groupedDevices" :key="group.id" class="device-group">
          <div v-if="group.id !== -1" @click="store.dispatch('setGroupPref', group.id)" class="group-header">
            <span v-if="store.getters.groupPref(group.id)">
              <i class="fas fa-angle-up"></i>
            </span>
            <span v-else>
              <i class="fas fa-angle-down"></i>
            </span>
            &nbsp;&nbsp;&nbsp;{{ group.name }}
            <!-- Contador de dispositivos en el grupo -->
            <span class="group-count">{{ group.devices.length }}</span>
          </div>
  
          <div v-if="group.id == -1 || store.getters.groupPref(group.id)" class="group-devices">
            <DeviceItem 
              v-for="(device) in group.devices" 
              :key="device.id" 
              class="device oblea" 
              :device="device"
              @click="handleDeviceClick(device)" 
              @contextmenu.prevent="markerContext($event, device.id)" 
            />
          </div>
        </div>
      </div>
    </div>
  </div>


</template>
  

<script setup>
  
import 'element-plus/es/components/button/style/css'
import 'element-plus/es/components/input/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
import 'element-plus/es/components/notification/style/css'
//import { Eleme } from '@element-plus/icons-vue'
import {ElButton, ElInput, ElMessage} from "element-plus";

import {ref,computed,inject,onMounted,onUnmounted,watch} from 'vue';
import {useStore} from "vuex"
import {useRoute} from 'vue-router';
//import {useRouter} from 'vue-router';

import KT from '../tarkan/func/kt.js';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
const store = useStore();
const route = useRoute();
//const router = useRouter();
const map = ref(null); 
import L from 'leaflet';

import DeviceItem from "./devices.item"



const markerContext = inject('markerContext');
//const markerClick = inject('markerClick');

const filteredDevices = ref([]);

const query = ref(window.localStorage.getItem('query') || '');
//const editDeviceRef = inject('edit-device');
const editGroupRef = inject('edit-group');

const now = ref(0);

const deviceId = computed(() =>(parseInt(route.params.device.id)));  // Ejemplo de un `id` de dispositivo almacenado en `state`

//const device = computed(() => {
 // return store.getters['devices/getDevice'](parseInt(route.params.deviceId));  // Obtenemos el dispositivo usando el `id`
//});

// Usar un watcher para imprimir el valor de 'device' en la consola

//const device = store.getters['devices/getDevice'](device.value.Id)

const deviceLimitExceded = ()=>{

}

const showTip = (evt,text)=>{
  window.$showTip(evt,text);
}

const hideTip = (evt,text)=>{
  window.$hideTip(evt,text);
}



//const showAlarmTip = ($event,deviceId)=>{
//  const position = store.getters['devices/getPosition'](deviceId);
//  showTip($event,KT('alarms.'+position.attributes.alarm))
//}


//const showStopped = ($event,deviceId)=>{
//
 // const position = store.getters['devices/getPosition'](deviceId);
////
 // showTip($event,'Parado há '+getLastUpdated(position.attributes.stoppedTime,now.value))
//}

//const showDriverTip = ($event,deviceId)=>{
  //const position = store.getters['devices/getPosition'](deviceId);

 // if(position.attributes['driverUniqueId']){
  //  const driver = store.getters['drivers/getDriverByUniqueId'](position.attributes['driverUniqueId']);
 //   if(driver){
 //     showTip($event,driver.name)
 //   }else{
 //     showTip($event,position.attributes['driverUniqueId'])
////    }
 // }

//}





const realDevices = ref(null);
// Variables para paginación (no usadas actualmente)

const validStates = [
    'motion',
    'anchor',
    'locked',
    'ignition',
    'driver',
    'alert'
]

const setSortingByState = ()=>{

    const tmp = store.getters['devices/sorting'].split("-");

    if(tmp[0]==='state') {
      let idx = validStates.findIndex((i)=> i===tmp[1])+1;
      if(idx>(validStates.length-1)){
        store.dispatch("devices/setSorting","name");
      }else {
        store.dispatch('devices/setSortingState', 'state-' + validStates[idx]);
      }
    }else{
      store.dispatch('devices/setSortingState','state-motion');
    }
}


// Definir imageUrl como un ref
//import {defineProps} from 'vue';

// Definir imageUrl como un ref reactivo
//const imageUrls = ref({});
// Definir props si es necesario (por ejemplo, el grupo)
const device = store.getters['devices/getDevice'](deviceId);




// Variable para controlar si estamos retornando a la vista de dispositivos
const isReturningToDevicesView = ref(false);

// Referencia al intervalo de tiempo para poder limpiarlo
const timeInterval = ref(null);

onMounted(() => {
  // Actualizar hora actual a intervalos (optimizado a 3 segundos)
  timeInterval.value = setInterval(() => {
    now.value = new Date();
  }, 3000);

  // Configuración para optimizar rendimiento al retornar a la vista
  isReturningToDevicesView.value = sessionStorage.getItem('returningToDevicesView') === 'true';
  
  // Cargar los dispositivos
  try {
    filteredDevices.value = recalcDevices();
  } catch (err) {
    console.error("Error al cargar dispositivos:", err);
  }
  
  // Si estamos retornando, eliminar la marca
  if (isReturningToDevicesView.value) {
    sessionStorage.removeItem('returningToDevicesView');
  }
    
  setSortingByState();

  // Si la ruta tiene el parámetro 'edit', abrimos el editor
  if (route.query.edit) {
    openEdit();
  }

  // Inicializar mapa si es necesario
  if (map.value) {
    map.value.leafletObject = L.map(map.value).setView([51.505, -0.09], 13);
  }

  // Guardar lista completa de dispositivos
  devices.value = store.getters['devices/getOrderedDevices'];
});

// Mover onUnmounted fuera de onMounted para evitar problemas de cierre
onUnmounted(() => {
  // Limpiar intervalo si existe
  if (timeInterval.value) {
    clearInterval(timeInterval.value);
    timeInterval.value = null;
  }
  
  // Marcar que estamos saliendo de la vista de dispositivos
  sessionStorage.setItem('returningToDevicesView', 'true');
});

// Debounce para a busca (query)
let queryUpdateTimeout = null;

watch(query, () => {
  // Cancelar timeout anterior se existir
  if (queryUpdateTimeout) {
    clearTimeout(queryUpdateTimeout);
  }
  
  // Definir novo timeout de 300ms para debounce durante digitação
  queryUpdateTimeout = setTimeout(() => {
    filteredDevices.value = recalcDevices();
  }, 300);
});

// Usando debounce para evitar múltiplas execuções em sequência
let deviceUpdateTimeout = null;

watch(()=> store.getters['devices/getOrderedDevices'].length, () => {
  // Cancelar timeout anterior se existir
  if (deviceUpdateTimeout) {
    clearTimeout(deviceUpdateTimeout);
  }
  
  // Definir novo timeout de 200ms para debounce
  deviceUpdateTimeout = setTimeout(() => {
    console.log("Mudança detectada nos dispositivos - recalculando lista");
    filteredDevices.value = recalcDevices();
  }, 200);
});


// Debounce para mudanças de ordenação
let sortingUpdateTimeout = null;

watch(()=> store.getters['devices/sorting'], () => {
  // Cancelar timeout anterior se existir
  if (sortingUpdateTimeout) {
    clearTimeout(sortingUpdateTimeout);
  }
  
  // Usar timeout mais curto para ordenação (100ms) pois é uma ação direta do usuário
  sortingUpdateTimeout = setTimeout(() => {
    filteredDevices.value = recalcDevices();
  }, 100);
});

// Método para manejar el scroll y asegurar que los contadores estén actualizados
const realScroll = () => {
  // Actualizar el contador de dispositivos totales
  const deviceCounter = document.querySelector('.devices-count');
  if (deviceCounter && filteredDevices.value) {
    deviceCounter.textContent = filteredDevices.value.length + ' dispositivos';
  }
  
  // Actualizar contador de filtros si es necesario
  if (query.value && query.value.length > 0) {
    const filterCounter = document.querySelector('.filter-counter');
    if (filterCounter) {
      // Usar la estructura HTML para mantener la burbuja de conteo
      const filterText = document.createElement('span');
      filterText.textContent = KT('device.filtered') + '\u00A0'; // \u00A0 es espacio no rompible
      
      const filterBubble = document.createElement('span');
      filterBubble.className = 'filter-bubble';
      filterBubble.textContent = filteredDevices.value.length;
      
      // Limpiar el contenido actual y agregar los nuevos elementos
      filterCounter.innerHTML = '';
      filterCounter.appendChild(filterText);
      filterCounter.appendChild(filterBubble);
    }
  }
}




//////////aqui getLastUpdate////////


// Variable para indicar si está calculando
if (!window.$isCalculating) {
  window.$isCalculating = false;
}

// Flag para indicar si el filtrado proviene de un botón
const isButtonFilter = ref(false);

// Función optimizada de recálculo de dispositivos con procesamiento por lotes
// Versión simplificada para mayor rendimiento
const recalcDevices = (onComplete = null) => {
  // Evitar recálculos simultáneos
  if (window.$isCalculating) {
    return filteredDevices.value || [];
  }
  
  window.$isCalculating = true;
  store.dispatch("setFiltering", false);

  // Guardar búsqueda en localStorage
  if (!isButtonFilter.value) {
    window.localStorage.setItem('query', query.value);
  }

  // Procesar filtros especiales (días, horas, etc.)
  const r = query.value.toLowerCase().matchAll(/(.*?):(?<sinal>\+|-|=)(?<tempo>\d*) (?<filtro>dias|minutos|horas|segundos)/gi);
  const s = r.next();

  // Buscar grupos que coincidan con la búsqueda
  let groupList = [];
  if (query.value && query.value.length > 2) {
    store.state.groups.groupList.forEach((g) => {
      if (g && g.name && String(g.name).toLowerCase().includes(query.value.toLowerCase())) {
        groupList.push(g.id);
      }
    });
  }

  // Implementación simplificada para mejorar rendimiento
  const allDevices = store.getters['devices/getOrderedDevices'] || [];
  let filteredResults = [];
  
  // Si no hay búsqueda ni filtros, mostrar todos los dispositivos
  if (!query.value && !isButtonFilter.value) {
    for (let i = 0; i < allDevices.length; i++) {
      const device = store.getters['devices/getDevice'](allDevices[i]);
      if (device && device.icon && device.icon[0]) {
        device.icon[0].addToMap();
      }
      if (device) filteredResults.push(device);
    }
  } 
  // Aplicar filtros de búsqueda
  else {
    const queryLower = query.value.toLowerCase();
    
    for (let i = 0; i < allDevices.length; i++) {
      const deviceId = allDevices[i];
      const device = store.getters['devices/getDevice'](deviceId);
      
      if (!device) continue;
      
      // Remover icono por defecto
      if (device.icon && device.icon[0] && typeof device.icon[0].remove === 'function') {
        device.icon[0].remove();
      }
      
      let visible = false;
      
      // Filtros de fecha
      if (s && s.value && s.value.groups) {
        store.dispatch("setFiltering", true);
        if (s.value.groups.filtro === 'dias') {
          const df = parseInt(s.value.groups.tempo) * 86400;
          const diff = Math.round((new Date().getTime() - new Date(device.lastUpdate).getTime()) / 1000);
          
          if ((s.value.groups.sinal === '+' && diff >= df) || 
              (s.value.groups.sinal === '-' && diff <= df)) {
            visible = true;
          }
        }
      }
      // Búsqueda de texto
      else if (query.value && query.value.length > 2) {
        store.dispatch("setFiltering", true);
        
        // Propiedades más comunes para la búsqueda
        if (device.name?.toLowerCase().includes(queryLower) || 
            device.status?.toLowerCase().replace('unknown', 'desconhecido').includes(queryLower) ||
            device.uniqueId?.toLowerCase().includes(queryLower) ||
            device.attributes?.placa?.toLowerCase().includes(queryLower)) {
          visible = true;
        }
        // Búsqueda en otros atributos
        else if (device.attributes) {
          for (const key in device.attributes) {
            if (device.attributes[key] && 
                String(device.attributes[key]).toLowerCase().includes(queryLower)) {
              visible = true;
              break;
            }
          }
        }
        
        // Filtro por grupo
        if (!visible && device.groupId !== 0 && groupList.includes(device.groupId)) {
          visible = true;
        }
      } 
      // Sin filtros, mostrar todo
      else if (!isButtonFilter.value) {
        visible = true;
      }
      
      // Agregar a resultados si es visible
      if (visible) {
        // Añadir icono al mapa
        if (device.icon && device.icon[0] && typeof device.icon[0].addToMap === 'function') {
          device.icon[0].addToMap();
        }
        filteredResults.push(device);
      }
    }
  }
  
  // Actualizar resultados
  filteredDevices.value = filteredResults;
  isButtonFilter.value = false;
  
  // Actualizar contador
  const deviceCounter = document.querySelector('.devices-count');
  if (deviceCounter) {
    deviceCounter.textContent = filteredResults.length + ' dispositivos';
  }
  
  // Scroll al inicio
  setTimeout(() => {
    const scrollContainer = document.querySelector('.devices-scroll-container');
    if (scrollContainer) {
      scrollContainer.scrollTop = 0;
    }
  }, 50);
  
  // Finalizar cálculo
  window.$isCalculating = false;
  
  // Ejecutar callback si existe
  if (typeof onComplete === 'function') {
    onComplete(filteredResults);
  }
  
  return filteredResults;
}



// Función para acceder a dispositivos filtrados (no usada actualmente)

const groupedDevices = computed(()=>{
  let showGroups = store.getters['mapPref']('groups');

  // IMPORTANTE: verificar primero si hay dispositivos filtrados
  if(!filteredDevices.value || filteredDevices.value.length === 0){
    // Caso especial: no hay dispositivos, devolver algo simple
    return [{id: -1, name: '', devices: [], open: true}];
  }

  if(showGroups){
    let tmp = {};
    const groups = store.state.groups.groupList || [];
    
    // Procesar cada dispositivo en los grupos
    filteredDevices.value.forEach((device)=>{
        if(!groups.find((g)=> g.id===device.groupId )){
          if (!tmp[0]) {
            tmp[0] = [];
          }
          tmp[0].push(device);
        }else {
          if (!tmp[device.groupId]) {
            tmp[device.groupId] = [];
          }
          tmp[device.groupId].push(device);
        }
    });

    let list = [];

    // Agregar grupo sin asignación primero
    if(tmp[0] && tmp[0].length > 0) {
      list.push({id: 0, name: 'Sem Grupo', devices: tmp[0], open: true});
    }
    
    // Agregar los demás grupos que tengan dispositivos
    groups.forEach((g)=>{
      if(tmp[g.id] && tmp[g.id].length > 0) {
        list.push({id: g.id, name: g.name, devices: tmp[g.id], open: true}); // Cambiado a open:true
      }
    });

    // Verificar que haya grupos
    if(list.length === 0) {
      list.push({id: -1, name: '', devices: [], open: true});
    }

    return list;
  } else {
    // Sin agrupación, un solo grupo con todos los dispositivos
    return [{id: -1, name: '', devices: filteredDevices.value || [], open: true}];
  }
})

const generatePDF = () => {
  const doc = new jsPDF('landscape', 'mm', 'a4'); // Configurar A4 apaisado
  doc.setFontSize(5); // Tamaño de fuente reducido
  
  const baseUrl = window.location.origin; // Obtener el dominio base de la app
  
  // Ruta relativa del logo
  const logoPath = '/tarkan/assets/custom/logo.png';
  
  // Construir la URL completa del logo
  const logoUrl = baseUrl + logoPath;
  
  // Añadir el logo en la posición deseada (ajustado a 2x1 cm)
  doc.addImage(logoUrl, 'PNG', 10, 10, 20, 5); // El logo tiene un tamaño de 2 cm x 1 cm
  
  // Título
  //doc.setFontSize(10); // Tamaño de fuente para el título
  doc.text("Reporte de Dispositivos Activos", 10, 25); // Título debajo del logo
  
  // Definición de columnas
  const columns = ["Unique ID", "Placa", "Nome", "Teléfono", "Operador", "ICCID", "Contacto", "Categoría", "Deshabilitado"];
  
  // Cabecera de la tabla
  doc.setFillColor(200, 200, 200); // Color de fondo para la cabecera
  doc.rect(10, 35, 280, 6, 'F'); // Ajustar altura de la cabecera (6 mm de alto)
  
  // Establecer color del texto
  doc.setTextColor(0);
  
  // Colocar los títulos de las columnas
  columns.forEach((col, index) => {
    doc.text(col, 12 + (index * 30), 40); // Ajustar posición por columna (y = 40 para que se vea bien)
  });
  
  // Posición inicial para las filas de la tabla
  let y = 45; // Inicializar posición Y
  let alternateColor = true;
  
  // Preparar los datos (esto debe ser adaptado según cómo recibes los datos)
  const usersData = filteredDevices.value.map(device => [
    device.uniqueId || '-',
    device.attributes?.placa || '-',
    device.name || '-',
    device.phone || '-',
    device.attributes?.operator || '-',
    device.attributes?.iccid || '-',
    device.contact || '-',
    device.category || '-',
    device.disabled ? 'Sí' : 'No'
  ]);
  
  // Ordenar dispositivos por última actualización (esto depende de tu estructura)
  const sortedDevices = [...usersData].sort((a, b) => {
    return new Date(b.lastUpdate) - new Date(a.lastUpdate); // Cambiar según la estructura de usersData
  });
  
  // Añadir los datos de cada dispositivo
  sortedDevices.forEach((data) => {
    // Color de fondo para renglones alternos
    doc.setFillColor(alternateColor ? 240 : 255, alternateColor ? 240 : 255, alternateColor ? 240 : 255);
    
    // Dibujar un rectángulo para cada fila (altura de cada fila: 6 mm)
    doc.rect(10, y, 280, 6, 'F'); 
  
    // Colocar el texto en cada celda
    data.forEach((cell, index) => {
      doc.text(cell, 12 + (index * 30), y + 4); // Ajustar la posición del texto (4 mm de margen en y)
    });
  
    // Alternar el color de las filas
    alternateColor = !alternateColor;
    
    // Incrementar la posición Y para la siguiente fila
    y += 6; // Ajustar la altura de las filas

    // Añadir una nueva página si es necesario
    if (y >= 195) { // Cambiar este valor si es necesario
      doc.addPage();
      y = 15; // Reiniciar la posición Y
      doc.text("Reporte de Dispositivos Activos", 10, 10);
      doc.rect(10, 15, 280, 6, 'F');
      columns.forEach((col, index) => {
        doc.text(col, 12 + (index * 30), 18); // Ajustar posición según la columna
      });
    }
  });

  // Numerar las páginas
  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.setFontSize(5);
    doc.text(`Página ${i} de ${totalPages}`, 280 - 20, 205 ); // Mover 3 cm más abajo
  }

  doc.save("reporte_dispositivos_activos.pdf");
};


const generateExcel = () => {
  // Definir las columnas
  const columns = ["Unique ID", "Placa", "Nome", "Teléfono", "Operador", "ICCID", "Contacto", "Categoría", "Deshabilitado"];

  // Preparar los datos
  const usersData = filteredDevices.value.map(device => [
    device.uniqueId || '-',
    device.attributes?.placa || '-',
    device.name || '-',
    device.phone || '-',
    device.attributes?.operator || '-',
    device.attributes?.iccid || '-',
    device.contact || '-',
    device.category || '-',
    device.disabled ? 'Sí' : 'No'
  ]);

  // Ordenar dispositivos por última actualización
  const sortedDevices = [...usersData].sort((a, b) => {
    return new Date(b.lastUpdate) - new Date(a.lastUpdate); // Cambiar según la estructura de usersData
  });

  // Crear una nueva hoja de trabajo
  const ws = XLSX.utils.aoa_to_sheet([columns, ...sortedDevices]);

  // Crear un nuevo libro de trabajo
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Dispositivos Activos");

  // Establecer opciones para la descarga
  XLSX.writeFile(wb, "reporte_dispositivos_activos.xlsx");
};
const generateJSON = () => {
  const usersData = filteredDevices.value.map(device => ({
    uniqueId: device.uniqueId || '-',
    placa: device.attributes?.placa || '-',
    name: device.name || '-',
    phone: device.phone || '-',
    operator: device.attributes?.operator || '-',
    iccid: device.attributes?.iccid || '-',
    contact: device.contact || '-',
    category: device.category || '-',
    disabled: device.disabled ? 'Sí' : 'No'
  }));

  const jsonString = JSON.stringify(usersData, null, 2); // Convertir a JSON con formato
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  
  const a = document.createElement('a');
  a.href = url;
  a.download = 'reporte_dispositivos_activos.json';
  a.click();
  URL.revokeObjectURL(url); // Limpiar el objeto URL
};
// Función para generar PDF de dispositivos fuera de línea

const generateOfflinePDF = () => {
  const doc = new jsPDF('landscape', 'mm', 'a4'); // Configurar A4 apaisado
  doc.setFontSize(5); // Tamaño de fuente reducido

  // Título
  doc.text("Reporte de Dispositivos Fuera de Línea", 10, 10);

  // Definición de columnas
  const columns = ["IMEI", "Placa", "Nombre", "Teléfono", "Operador", "ICCID", "Última Actualización", "Diferencia (Horas)"];
  
  // Cabecera de la tabla
  doc.setFillColor(200, 200, 200);
  doc.rect(10, 15, 280, 6, 'F'); // Ajustar altura de la cabecera
  doc.setTextColor(0);
  columns.forEach((col, index) => {
    doc.text(col, 12 + (index * 35), 18); // Ajustar posición según la columna
  });

  let y = 25; // Inicializar posición Y
  let alternateColor = true;

  const now = new Date();
  
  // Filtrar dispositivos fuera de línea (no actualizados en las últimas 24 horas)
  const offlineDevices = store.getters['devices/getOrderedDevices'].filter(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    const lastUpdate = new Date(device.lastUpdate);
    return (now - lastUpdate) > (24 * 60 * 60 * 1000); // No actualizados en las últimas 24 horas
  });

  // Crear un array de datos para la tabla
  const rows = offlineDevices.map(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    const deviceName = device.name.length > 30 ? device.name.substring(0, 27) + '...' : device.name;

    // Obtener la fecha de la última actualización
    const lastUpdate = new Date(device.lastUpdate);
    const hoursDiff = Math.floor((now - lastUpdate) / (1000 * 60 * 60));

    return [
      device.uniqueId || '-', // Agregar el uniqueId como IMEI
      device.attributes?.placa || '-',
      deviceName,
      device.phone || '-',
      device.attributes?.operator || '-',
      device.attributes?.iccid || '-',
      lastUpdate, // Mantener la fecha como objeto Date para ordenar correctamente
      hoursDiff // Solo dejar la diferencia en horas
    ];
  });

  // Ordenar filas por última actualización (de mayor a menor)
  rows.sort((a, b) => b[6] - a[6]); // Comparar fechas directamente

  // Agregar los datos a la tabla
  rows.forEach((data) => {
    // Color de fondo para renglones alternos
    doc.setFillColor(alternateColor ? 240 : 255);
    doc.rect(10, y, 280, 6, 'F'); // Altura reducida

    // Escribir el texto alineado a la izquierda
    doc.setTextColor(0);
    data.forEach((info, i) => {
      // Formatear la fecha para la columna correspondiente
      if (i === 6) {
        const formattedDate = `${data[6].getDate()}/${data[6].getMonth() + 1}/${data[6].getFullYear()} ${data[6].getHours()}:${data[6].getMinutes().toString().padStart(2, '0')}`;
        doc.text(formattedDate, 12 + (i * 35), y + 4);
      } else {
        doc.text(info.toString(), 12 + (i * 35), y + 4); // Ajustar posición según la columna
      }
    });

    y += 6; // Espacio entre renglones (reducido)
    alternateColor = !alternateColor; // Alterna el color

    // Añadir una nueva página si es necesario
    if (y >= 195) { // Cambiar este valor si es necesario
      doc.addPage();
      y = 15; // Reiniciar la posición Y
      doc.text("Reporte de Dispositivos Fuera de Línea", 10, 10);
      doc.rect(10, 15, 280, 6, 'F');
      columns.forEach((col, index) => {
        doc.text(col, 12 + (index * 35), 18); // Ajustar posición según la columna
      });
    }
  });

  // Numerar las páginas
  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    doc.setPage(i);
    doc.setFontSize(5);
    doc.text(`Página ${i} de ${totalPages}`, 280 - 20, 205); // Mover 3 cm más abajo
  }

  doc.save("reporte_dispositivos_fuera_de_linea.pdf");
};

const generateOfflineExcel = () => {
  const columns = ["IMEI", "Placa", "Nombre", "Teléfono", "Operador", "ICCID", "Última Actualización", "Diferencia (Horas)"];
  const now = new Date();

  // Filtrar dispositivos fuera de línea en las últimas 24 horas
  const offlineDevices = store.getters['devices/getOrderedDevices'].filter(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    const lastUpdate = new Date(device.lastUpdate);
    return (now - lastUpdate) > (24 * 60 * 60 * 1000); // Últimas 24 horas
  });

  // Crear un array de datos para la tabla
  const rows = offlineDevices.map(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    const deviceName = device.name.length > 30 ? device.name.substring(0, 27) + '...' : device.name;

    const lastUpdate = new Date(device.lastUpdate);
    const hoursDiff = Math.floor((now - lastUpdate) / (1000 * 60 * 60));

    return [
      device.uniqueId || '-',
      device.attributes?.placa || '-',
      deviceName,
      device.phone || '-',
      device.attributes?.operator || '-',
      device.attributes?.iccid || '-',
      lastUpdate, // Mantener la fecha como objeto Date
      hoursDiff // Diferencia en horas
    ];
  });

  // Crear una nueva hoja de trabajo
  const ws = XLSX.utils.aoa_to_sheet([columns, ...rows.map(row => {
    return [
      row[0],
      row[1],
      row[2],
      row[3],
      row[4],
      row[5],
      `${row[6].getDate()}/${row[6].getMonth() + 1}/${row[6].getFullYear()} ${row[6].getHours()}:${row[6].getMinutes().toString().padStart(2, '0')}`,
      row[7]
    ];
  })]);

  // Crear un nuevo libro de trabajo
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Dispositivos Fuera de Línea");

  // Establecer opciones para la descarga
  XLSX.writeFile(wb, "reporte_dispositivos_fuera_de_linea.xlsx");
};

const generateOfflineJSON = () => {
  const now = new Date();
  const offlineDevices = store.getters['devices/getOrderedDevices'].filter(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    const lastUpdate = new Date(device.lastUpdate);
    return (now - lastUpdate) > (24 * 60 * 60 * 1000); // Últimas 24 horas
  });

  const jsonData = offlineDevices.map(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    return {
      uniqueId: device.uniqueId || '-',
      placa: device.attributes?.placa || '-',
      nombre: device.name || '-',
      telefono: device.phone || '-',
      operador: device.attributes?.operator || '-',
      iccid: device.attributes?.iccid || '-',
      ultimaActualizacion: device.lastUpdate,
      diferenciaHoras: Math.floor((now - new Date(device.lastUpdate)) / (1000 * 60 * 60))
    };
  });

  const jsonString = JSON.stringify(jsonData, null, 2);
  const blob = new Blob([jsonString], { type: 'application/json' });
  const url = URL.createObjectURL(blob);
  
  const a = document.createElement('a');
  a.href = url;
  a.download = 'reporte_dispositivos_fuera_de_linea.json';
  a.click();
  URL.revokeObjectURL(url); // Limpiar el objeto URL
};







// Función para filtrar dispositivos por última hora
const filterLastHour = () => {
  // Establecer flag para indicar que es un filtro por botón
  isButtonFilter.value = true;
  
  const now = new Date();
  const filtered = store.getters['devices/getOrderedDevices'].filter(deviceId => {
    const device = store.getters['devices/getDevice'](deviceId);
    if (!device || !device.lastUpdate) return false;
    
    const lastUpdate = new Date(device.lastUpdate);
    return (now - lastUpdate) <= (60 * 60 * 1000); // Última hora
  });

  // Actualizar filteredDevices con la nueva lista
  filteredDevices.value = filtered.map(id => {
    const device = store.getters['devices/getDevice'](id);
    if (device && device.icon && device.icon[0] && typeof device.icon[0].addToMap === 'function') {
      device.icon[0].addToMap();
    }
    return device;
  }).filter(Boolean); // Eliminar valores nulos
  
  // Notificar al usuario
  ElMessage({
    message: `${filteredDevices.value.length} dispositivos actualizados en la última hora`,
    type: 'success',
    duration: 3000
  });
  
  // Forzar actualización de la vista
  setTimeout(() => {
    const scrollContainer = document.querySelector('.devices-scroll-container');
    if (scrollContainer) {
      realScroll({ target: scrollContainer, stopPropagation: () => {} });
    }
  }, 50);
  
  // Cerrar el modal después de filtrar
  closeModal();
};

const isShowModal = ref(false);

function closeModal() {
  isShowModal.value = false;
}
////de informes///
function showModal() {
 isShowModal.value = true;
}




/*
const getLastUpdated = (t, tt) => {
  // Si t es null, significa que el valor aún no ha sido establecido.
  if (t === null) {
    return KT('new');
  }

  // Usamos el tiempo actual si tt no está definido.
  tt = tt || new Date();

  // Calculamos la diferencia en segundos entre las dos fechas.
  const diffInSeconds = Math.round((new Date(tt).getTime() - new Date(t).getTime()) / 1000);

  // Si la diferencia es negativa (esto no debería suceder normalmente), devolvemos 'ahora'
  if (diffInSeconds < 0) {
    return KT('now');
  }

  // Si la diferencia es mayor a un día (86400 segundos)
  if (diffInSeconds >= 86400) {
    const days = Math.floor(diffInSeconds / 86400);
    return `${days} ${KT('days')}`;
  }

  // Si la diferencia es mayor a una hora (3600 segundos)
  if (diffInSeconds >= 3600) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} ${KT('hours')}`;
  }

  // Si la diferencia es mayor a un minuto (60 segundos)
  if (diffInSeconds >= 60) {
    const minutes = Math.floor(diffInSeconds / 60);
    return `${minutes} ${KT('minutes')}`;
  }

  // Si la diferencia es menor a un minuto, devolvemos el texto para menos de un minuto
  return KT('lessMinute');
};*/

const editDeviceRef = inject('edit-device');
const openEdit = (id = false)=>{
  editDeviceRef.value.editDevice(id?id:device.value.Id);
}

// Função para calcular a altura foi removida pois voltamos ao layout original
watch(()=> route.query.edit,(a)=>{
  if(a){
    openEdit();
  }
})






const handleDeviceClick = (device) => {
  // Mostrar los detalles del dispositivo


  // Hacer zoom en el dispositivo
  flyToDevice(device);
};


const flyToDevice = inject('flyToDevice');
/*
const markerClick = (e) =>{

  console.log(e);

  const deviceId = (e.target)?e.target.options.id:e;
  router.push('/devices/'+deviceId);
  const device = store.getters['devices/getDevice'](deviceId);

  store.commit("devices/setFollow", deviceId);
  //device.icon.remove();
  
  device.icon.forEach((i)=>{
      i.bringToFront();
  });


  flyToDevice(device);
  
}*/


  
  

//const imageUrls = ref({});
//const isDeviceImageLoaded = ref(true);




const devices = ref([]);

// Función que maneja el evento de carga exitosa de una imagen


// Función que maneja el evento de error al cargar la imagen

// Lógica para cargar las imágenes de los dispositivos

</script>

<style scoped>
.header-container {
  padding: 8px; /* Reducido padding general */
  padding-top: 15px; /* Reducido a 15px - estaba en 30px */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 12px; /* Reducido margen inferior */
  margin-top: 5px; /* Reducido a 5px - estaba en 20px */
}

.search-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px; /* Reducido espacio entre elementos */
  height: 30px; /* Altura fija reducida */
  flex-wrap: nowrap; /* Cambiado a nowrap para mantener los elementos en línea */
width: 100%;
}

.search-input {
  flex: 0.8; /* Cambiado para que ocupe 80% del espacio */
  --el-input-border-radius: 8px;
  font-size: 12px;
  overflow: hidden;
}

/* Animación mejorada para el placeholder de la barra de búsqueda con compatibilidad en Safari */
.search-input :deep(input::placeholder) {
  animation: fade-in-out 6s ease-in-out infinite;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  opacity: 0.9;
  width: 100%;
  position: relative;
}

/* Animación de fade en lugar de text-indent para mejor compatibilidad con Safari */
@keyframes fade-in-out {
  0% { opacity: 0.5; }
  50% { opacity: 1; }
  100% { opacity: 0.5; }
}

.actions-group {
  display: flex;
  gap: 5px; /* Reducido espacio entre botones */
  flex: 0.2; /* Cambiado para que ocupe 20% del espacio */
  justify-content: flex-end;
}

.action-button {
  height: 26px; /* Reducido a la mitad aproximadamente */
  width: 26px; /* Reducido a la mitad aproximadamente */
  border-radius: 4px; /* Bordes más pequeños */
  padding: 0 !important; /* Sin padding */
  min-height: 26px !important; /* Forzar altura mínima */
}

.action-button :deep(i) {
  font-size: 12px; /* Iconos más pequeños */
}

.device-container {
  border: silver 2px solid;
  border-radius: 8px;
  margin-top: 5px; /* Reducido a 5px - estaba en 10px */
  margin-bottom: 10px; /* REDUCIDO */
  height: calc(75vh);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  background-color: white;
  overflow: hidden; /* Impide que el contenido desborde */
  display: flex;
  flex-direction: column;
  will-change: transform; /* Optimiza rendimiento */
}

/* Nuevo: Contador de dispositivos */
.devices-count {
  position: absolute;
  top: -20px; /* Ajustado a -20px - estaba en -25px */
  right: 10px;
  font-size: 12px;
  background-color: #f0f0f0;
  padding: 3px 10px;
  border-radius: 12px;
  color: #666;
  font-weight: bold;
  z-index: 10;
}

/* Contador de filtros */
.filter-counter {
  position: absolute;
  top: -20px; /* Ajustado a -20px - estaba en -25px */
  left: 10px;
  font-size: 12px;
  background-color: #f0f7ff;
  padding: 3px 10px;
  border-radius: 12px;
  color: var(--el-color-primary);
  font-weight: bold;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 5px;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(5px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Burbuja del contador de filtros */
.filter-bubble {
  background-color: var(--el-color-primary);
  color: white;
  font-size: 11px;
  font-weight: bold;
  border-radius: 50%;
  min-width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.2);
  animation: pulseBubble 2s infinite;
}

@keyframes pulseBubble {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.deviceHead {
  padding: 10px;
  border-bottom: 1px solid #eee;
  flex-shrink: 0; /* Impede que o cabeçalho encolha */
  margin-top: 5px; /* Reducido a 5px - estaba en 10px */
}

.devices-scroll-container {
  overflow-y: auto; /* Cambiado de scroll a auto para mejor comportamiento */
  height: auto; /* Altura automática */
  flex-grow: 1; /* Hace que el contenedor crezca para llenar el espacio restante */
  padding: 10px;
  position: relative; /* Para posicionamiento adecuado */
  background-color: #ffffff; /* Fondo blanco para evitar transparencias */
  overscroll-behavior: contain; /* Evita comportamientos extraños al hacer scroll */
  -webkit-overflow-scrolling: touch; /* Scroll suave en iOS */
}

.device-group {
  margin-bottom: 15px;
}

.group-header {
  background: #f7f7f7;
  padding: 8px 12px; /* REDUCIDO */
  cursor: pointer;
  font-size: 14px;
  border-radius: 6px;
  margin-bottom: 8px; /* REDUCIDO */
  font-weight: 500;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-count {
  background: #e0e0e0;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 11px;
  font-weight: bold;
  color: #666;
  margin-left: auto;
}

.group-devices {
  display: flex;
  flex-direction: column;
  gap: 10px; /* REDUCIDO para más compacto */
}

.actions-footer {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
}

.reports-button-top {
  background-color: #409EFF;
  color: white;
}

/* Estilos existentes modificados */
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icons div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-right: var(--el-border-color-light) 1px dotted;
  font-size: 12px;
  padding: 0 8px;
}

.icons div i {
  font-size: 16px;
  margin-right: 5px;
}

.icons div:first-child {
  border-right: none;
}

.icons div span {
  display: flex;
  padding: 4px;
  padding-left: 5px;
}

.subtitle {
  margin-top: 20px;
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--el-text-color-primary);
  margin-bottom: 10px;
}

.subtitle i {
  font-size: 14px;
  margin-right: 8px;
}

.isDisabled {
  opacity: 0.4;
}

.button-container {
  margin-top: 20px;
  display: flex;
  gap: 12px;
}

.modal-container {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  border-radius: 12px;
  width: 350px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-header {
  border-bottom: 1px solid #e5e7eb;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-body {
  padding: 30px;
}

.modal-footer {
  padding: 20px;
  border-top: 1px solid #e5e7eb;
  display: flex;
  justify-content: flex-end;
}

.decline-button {
  background-color: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.decline-button:hover {
  background-color: #f5f5f5;
}

.accept-button {
  background-color: #38a169;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  font-weight: 500;
  border: none;
}

.custom-button {
  background-color: #409EFF;
  color: white;
  border: none;
  font-size: 14px;
  border-radius: 6px;
  padding: 12px 15px;
  width: 240px;
  margin-bottom: 5px;
  transition: background-color 0.3s;
  text-align: center;
}

.custom-button:hover {
  background-color: #337ecc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.device-list {
  width: 100%;
  min-height: 100%;
  overflow: visible;
  position: relative;
  padding-bottom: 20px; /* Añadir espacio inferior para evitar cortes */
  will-change: transform; /* Optimiza rendimiento de renderizado */
}

.devices-scroll-container {
  overflow-y: auto;
  height: calc(75vh); 
  flex-grow: 1;
  padding: 10px;
  position: relative;
  background-color: #ffffff;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
}

.group-devices {
  margin-bottom: 15px;
}

.device {
  transition: transform 0.2s, box-shadow 0.3s;
  margin-bottom: 10px; /* REDUCIDO a 10px */
  overflow: hidden;
  max-width: 100%;
  display: block;
  min-height: 145px; /* REDUCIDO a 145px */
  height: 145px; /* REDUCIDO a 145px */
  background-color: #ffffff;
  border-radius: 8px;
  backface-visibility: hidden;
  will-change: transform, opacity;
  position: relative;
}

.device:hover {
  background: var(--el-color-primary-light-8);
  background-color: #f8f8f8;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.device.isDisabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.device .el-tooltip {
  position: absolute;
  top: 10px;
  right: 10px;
}

.sorting-buttons-container {
  display: flex;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 6px 8px; /* Reducido padding */
  border-radius: 6px; /* Bordes más pequeños */
  gap: 5px; /* Menor espacio entre botones */
  margin-bottom: 8px; /* Reducido margen */
  height: 25px; /* Altura fija reducida */
  align-items: center; /* Centrar verticalmente */
}

.sorting-button {
  background-color: #e8e8e8;
  color: #333;
  font-weight: 500;
  padding: 2px 6px; /* Reducido padding */
  font-size: 9px; /* Letra más pequeña */
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px; /* Bordes más pequeños */
  cursor: pointer;
  flex-grow: 1;
  transition: background-color 0.2s, transform 0.1s;
  text-align: center;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  white-space: nowrap; /* Evitar salto de línea */
  overflow: hidden; /* Ocultar exceso */
  text-overflow: ellipsis; /* Mostrar elipsis */
  height: 18px; /* Altura fija reducida */
  line-height: 1; /* Línea ajustada */
}

.sorting-button:hover {
  background-color: #d0d0d0;
  transform: translateY(-1px);
}

.sorting-button i {
  margin-left: 3px; /* Reducido margen */
  font-size: 8px; /* Iconos más pequeños */
}

.loading-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
  font-size: 16px;
  text-align: center;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
</style>